import { isGraphQLError } from '@/graphql'
import { Groups, listGroups } from '@/graphql/admin/users'
import { CellClickHandler, Table, TableDataCell } from '@/store/module/table'

type DelayCheckedAction = { id: string; checked: boolean }
type RequiredId = Required<Pick<TableDataCell, 'id'>>
type TableDataRequiredId = [TableDataCell, TableDataCell & RequiredId]

export class GroupsTable extends Table {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onCheckedHandler = () => {}
  private delayCheckedActions: DelayCheckedAction[] = []

  constructor() {
    super()

    this.columns = [
      {
        icon: 'mdi-checkbox-blank-off-outline',
        click: this.groupTableColumnClick,
        width: '42px',
      },
      { text: '権限' },
      { text: '説明' },
    ]
  }

  // グループにチェックが一つでも入っているかどうか
  get isChecked() {
    return this.table?.data.find((data) => data[0].checked) !== undefined
  }

  // チェックされたグループの配列を取得
  get checkedGroups() {
    const checked = this.table?.data.filter((data) => data[0].checked)
    if (!checked) {
      return []
    }

    return checked
      .filter((data): data is TableDataRequiredId => data[1].id !== undefined)
      .map((data) => data[1].id)
  }

  // グループにチェックした時のカスタムハンドラを登録する
  onChecked(handler: () => void) {
    this.onCheckedHandler = handler
  }

  // グループリストのチェック状態を初期化
  resetChecked() {
    this.table?.data.forEach((data) => {
      if (data[0]) {
        data[0].checked = false
      }
    })
  }

  /**
   * グループのチェック
   *
   * @param id グループIDまたはグループ名
   * @param checked チェック状態
   */
  checked(id: string, checked = true) {
    // 遅延評価
    if (!this.table?.data) {
      this.delayCheckedActions.push({ id, checked })
      return
    }

    const name = this.groupLocale(id)?.name ?? id
    const target = this.table?.data.find((data) => data[1].text === name)

    if (target) {
      target[0].checked = checked
    }
  }

  // すべてのチェックボックスのチェックをはずす
  private groupTableColumnClick: CellClickHandler = () => {
    this.table?.data.forEach((data) => (data[0].checked = false))
    this.onCheckedHandler()
  }

  // もし管理者がチェックされた場合は、その他のチェックをすべて外す
  private groupTableDataClick: CellClickHandler = (cell, row) => {
    // 管理者以外のグループがチェックされたとき
    const isNotAdminChecked =
      cell.checked == true && row[1].text !== Groups.Administrator.name

    // 管理者グループ
    const admin = this.table?.data.find(
      (data) => data[1].text === Groups.Administrator.name,
    )?.[0]

    if (!admin) {
      return
    }

    // 管理者がチェックされた時は、管理者以外のグループのチェックをはずす
    // 管理者以外のグループがチェックされた場合は、管理者のチェックをはずす
    if (!isNotAdminChecked && admin.checked) {
      this.table?.data.forEach((data) => {
        if (data[1].text !== Groups.Administrator.name) {
          data[0].checked = false
        }
      })
    } else {
      admin.checked = false
    }

    this.onCheckedHandler()
  }

  protected async request() {
    if (this.pageRequesting) {
      return
    }

    this.pageRequesting = true
    try {
      const result = await listGroups(this.nextToken)
      const resultGroups = result.data?.adminListGroups
      this.nextToken = resultGroups?.nextToken
        ? (resultGroups.nextToken as string)
        : undefined

      resultGroups?.groups.forEach((group) => {
        const locale = this.groupLocale(group.id)
        if (locale) {
          this.table?.data.push([
            {
              checked: false,
              click: this.groupTableDataClick,
            },
            { id: group.id, text: locale.name },
            { text: locale.description },
          ])
        }
      })

      // チェック状態の遅延評価
      this.executeDelayChecked()
    } catch (err) {
      if (isGraphQLError(err)) {
        if (this.table?.data) {
          this.table.errors = []
        }

        err.errors?.forEach((error) => {
          if (error.errorType == 'Unauthorized') {
            this.table?.errors.push('表示する権限がありません')
          } else {
            console.log(error)
          }
        })
      } else {
        console.log(err)
      }
    } finally {
      this.pageRequesting = false
    }
  }

  private groupLocale(id: string) {
    return Object.entries(Groups).find(([key]) => key == id)?.[1]
  }

  private executeDelayChecked() {
    this.delayCheckedActions.forEach((action) => {
      this.checked(action.id, action.checked)
      console.log('executeDelayChecked', action.id)
    })
    this.delayCheckedActions = []
    this.onCheckedHandler()
  }
}
