import { isGraphQLError } from '@/graphql'
import {
  CreateUserErrorType,
  deleteUser as adminDeleteUser,
  getUser,
  updateUser as adminUpdateUser
} from '@/graphql/admin/users'
import { BaseStore } from '@/store'
import storeTheList, { ListStore } from '@/store/admin/users/list'
import storeTheApp from '@/store/app'
import { KeyPair } from '@/store/module/table'
import ClipboardJS from 'clipboard'
import * as Password from 'generate-password'
import {
  inject,
  InjectionKey,
  onBeforeUnmount,
  onMounted,
  reactive,
  watch
} from 'vue'
import { Router, useRouter } from 'vue-router'
import { GroupsTable } from './groups-table'

const groupTable = new GroupsTable()
let appList: ListStore | undefined
let router: Router | undefined

interface EditUserInput {
  password: string
  temporary: boolean
}

interface Loading {
  submit: boolean
}

interface Show {
  input: boolean
  complete: boolean
  deleteComfirm: boolean
}

interface Disabled {
  submit: boolean
  delete: boolean
}

interface State {
  username: string
  loginUsername?: string
  input: EditUserInput
  show: Show
  loading: Loading
  disabled: Disabled
  errors: string[]
  result: string
  // ユーザー作成後、ユーザーテーブルを強制的にリロードさせるフラグ
  forceRealodUsersTable: boolean
}

const state = reactive<State>({
  username: '',
  loginUsername: undefined,
  input: {
    password: '',
    temporary: true,
  },
  show: {
    input: false,
    complete: false,
    deleteComfirm: false,
  },
  loading: {
    submit: false,
  },
  disabled: {
    submit: true,
    delete: true,
  },
  errors: [],
  result: '',
  // ユーザー作成後、ユーザーテーブルを強制的にリロードさせるフラグ
  forceRealodUsersTable: false,
})

const watchInputSubmit = () => {
  // なにか入力されているかどうか
  // const validInput = state.input.password.length > 0

  // グループにチェックが一つでも入っているかどうか
  const validGroupChecked = groupTable.isChecked

  state.disabled.submit = !validGroupChecked

  state.result = `ユーザー名:\n${state.username}\n`

  if (state.input.password.length > 0) {
    state.result += `\nパスワード:\n${state.input.password}\n`
  }
}

const setup = () => {
  const keys = groupTable.setup('admin-groups-list')
  groupTable.onChecked(watchInputSubmit)

  const appStore = inject(storeTheApp.key, storeTheApp)
  state.loginUsername = appStore.state.loginUser?.getUsername()
  watch(appStore.state, (appState) => {
    state.loginUsername = appState.loginUser?.getUsername()
  })

  appList = inject(storeTheList.key)

  router = useRouter()
  const username = router.currentRoute.value.params.id as string
  state.username = username

  onMounted(async () => {
    resetState()

    try {
      // ユーザーデータを取得し現在設定されているグループにチェックを入れる
      const result = await getUser(username)
      result.data?.adminGetUser?.groups.forEach((group) => {
        groupTable.checked(group.id, true)
      })
    } catch (err) {
      if (isGraphQLError(err)) {
        const isNotFound = err.errors?.find(
          (error) => 'UserNotFoundException' === error.errorType,
        )

        if (isNotFound) {
          appList?.addFlash(
            `ユーザー <b>${state.username}</b> は存在しません。`,
            'mdi-alert-circle-outline',
            'error',
          )
          state.forceRealodUsersTable = true
          router?.replace('/admin/users')
        } else {
          err.errors?.forEach((error) => {
            const e = Object.entries(CreateUserErrorType).find(
              ([type]) => type === error.errorType,
            )
            state.errors.push(e ? e[1] : error.message)
          })
        }
      } else {
        console.log(err)
      }
    } finally {
      showInput()
    }

    watchInputSubmit()

    new ClipboardJS('.complete-result-copy', {
      text: () => state.result,
    })
  })

  onBeforeUnmount(() => {
    // ユーザー作成、削除を行った場合で、ユーザーリストがある場合、ユーザーリストをリロードさせる
    if (appList && state.forceRealodUsersTable) {
      appList.state.reloadUsersTable = true
    }
  })

  // 入力監視
  watch(state.input, watchInputSubmit)

  return keys
}

const updateUser = async () => {
  state.errors = []
  state.loading.submit = true

  const password =
    state.input.password.length > 0 ? state.input.password : undefined

  try {
    await adminUpdateUser(
      state.username,
      groupTable.checkedGroups,
      password,
      !state.input.temporary,
    )
    showComplete()
  } catch (err) {
    if (isGraphQLError(err)) {
      err.errors?.forEach((error) => {
        const e = Object.entries(CreateUserErrorType).find(
          ([type]) => type === error.errorType,
        )
        state.errors.push(e ? e[1] : error.message)
      })
    } else {
      console.log(err)
    }
  } finally {
    state.loading.submit = false
  }
}

const deleteUser = async () => {
  state.errors = []
  state.loading.submit = true

  try {
    await adminDeleteUser(state.username)

    appList?.addFlash(
      `ユーザー　<b>${state.username}</b> を削除しました。`,
      'mdi-check-circle-outline',
      'success',
    )
    state.forceRealodUsersTable = true
    router?.replace('/admin/users')
  } catch (err) {
    if (isGraphQLError(err)) {
      err.errors?.forEach((error) => {
        const e = Object.entries(CreateUserErrorType).find(
          ([type]) => type === error.errorType,
        )
        state.errors.push(e ? e[1] : error.message)
      })
    } else {
      console.log(err)
    }
  } finally {
    hideDeleteConfirm()
    state.loading.submit = false
  }
}

const showInput = () => {
  state.show.input = true
  state.show.complete = false
  state.input.password = ''
  state.disabled.submit = true
}

const showComplete = () => {
  state.show.input = false
  state.show.complete = true
  state.forceRealodUsersTable = true
}

const showDeleteConfirm = () => {
  state.show.deleteComfirm = true
}

const hideDeleteConfirm = () => {
  state.show.deleteComfirm = false
}

const generatePassword = () => {
  state.input.password = Password.generate({
    uppercase: true,
    lowercase: true,
    numbers: true,
    strict: true,
    length: 8,
  })
}

const resetState = () => {
  state.input.password = ''
  state.input.temporary = true
  state.errors = []
  state.show.input = false
  state.show.complete = false
  state.disabled.submit = true
  state.forceRealodUsersTable = false

  // 自分自身は削除できない
  state.disabled.delete = state.username === state.loginUsername

  // グループリストのチェック状態を初期化
  groupTable.resetChecked()
}

interface Store {
  state: State
  setup: () => KeyPair
  updateUser: () => Promise<void>
  deleteUser: () => Promise<void>
  generatePassword: () => void
  showInput: () => void
  showDeleteConfirm: () => void
  hideDeleteConfirm: () => void
}

type S = Readonly<Store>
const key: InjectionKey<S> = Symbol()
const store: BaseStore<S> = {
  key,
  state,

  setup,
  updateUser,
  deleteUser,
  generatePassword,
  showInput,
  showDeleteConfirm,
  hideDeleteConfirm,
}

export default store
