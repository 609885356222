
import { defineComponent, inject } from 'vue'

import Table from '@/components/parts/Table.vue'
import FlashBanner from '@/components/parts/FlashBanner.vue'
import listStore from '@/store/admin/users/list'

export default defineComponent({
  name: 'AdminUsersList',
  components: {
    Table,
    FlashBanner,
  },
  setup() {
    const store = inject(listStore.key)
    if (!store) {
      throw new Error()
    }

    const usersTableKey = store.setup()

    return {
      store,
      usersTableKey,
    }
  },
})
