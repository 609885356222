
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FlashBanner',
  emits: ['close'],
  props: {
    text: {
      type: String,
    },
    color: {
      type: String,
      default: 'success',
    },
    icon: {
      type: String,
    },
  },
  setup(props, context) {
    const onClose = () => {
      context.emit('close', props.text)
    }

    return { onClose }
  },
})
