import { GraphQLMutationResult, GraphQLQueryResult } from '@/graphql'
import API, { graphqlOperation } from '@aws-amplify/api'
import gql from 'graphql-tag'
export * from './consts'

export const listUsers = (nextToken?: string) =>
  API.graphql(
    graphqlOperation(
      gql`
        query ($nextToken: String) {
          adminListUsers(nextToken: $nextToken) {
            users {
              id
              status
              enabled
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      `,
      {
        nextToken,
      },
    ),
  ) as GraphQLQueryResult<'adminListUsers'>

export const listGroups = (nextToken?: string) =>
  API.graphql(
    graphqlOperation(
      gql`
        query ($nextToken: String) {
          adminListGroups(nextToken: $nextToken) {
            groups {
              id
            }
            nextToken
          }
        }
      `,
      {
        nextToken,
      },
    ),
  ) as GraphQLQueryResult<'adminListGroups'>

export const getUser = (username: string) =>
  API.graphql(
    graphqlOperation(
      gql`
        query ($username: String!) {
          adminGetUser(username: $username) {
            id
            status
            enabled
            createdAt
            updatedAt
            groups {
              id
            }
          }
        }
      `,
      {
        username,
      },
    ),
  ) as GraphQLQueryResult<'adminGetUser'>

export const createUser = (
  username: string,
  groups: string[],
  password: string,
  permanent?: boolean,
) =>
  API.graphql(
    graphqlOperation(
      gql`
        mutation (
          $username: String!
          $groups: [String!]!
          $password: String!
          $permanent: Boolean = false
        ) {
          adminCreateUser(
            username: $username
            groups: $groups
            password: $password
            permanent: $permanent
          ) {
            id
            status
            enabled
            createdAt
            updatedAt
            groups {
              id
            }
          }
        }
      `,
      {
        username,
        groups,
        password,
        permanent,
      },
    ),
  ) as GraphQLMutationResult<'adminCreateUser'>

export const updateUser = (
  username: string,
  groups: string[],
  password?: string,
  permanent?: boolean,
) =>
  API.graphql(
    graphqlOperation(
      gql`
        mutation (
          $username: String!
          $groups: [String!]!
          ${password ? `$password: String` : ``}
          $permanent: Boolean = false
        ) {
          adminUpdateUser(
            username: $username
            groups: $groups
            ${password ? `password: $password` : ``}
            permanent: $permanent
          ) {
            id
            status
            enabled
            createdAt
            updatedAt
            groups {
              id
            }
          }
        }
      `,
      {
        username,
        groups,
        password,
        permanent,
      },
    ),
  ) as GraphQLMutationResult<'adminUpdateUser'>

export const deleteUser = (username: string) =>
  API.graphql(
    graphqlOperation(
      gql`
        mutation ($username: String!) {
          adminDeleteUser(username: $username)
        }
      `,
      {
        username,
      },
    ),
  ) as GraphQLMutationResult<'adminDeleteUser'>
