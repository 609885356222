import { BaseStore } from '@/store'
import { KeyPair } from '@/store/module/table'
import { InjectionKey, onUnmounted, reactive } from 'vue'
import { UsersTable } from './users-table'

const usersTable = new UsersTable()

interface Disabled {
  create: boolean
  edit: boolean
}

interface Flash {
  text: string
  color?: string
  icon?: string
}

interface State {
  reloadUsersTable: boolean
  disabled: Disabled
  errors: string[]
  flashes: Flash[]
}

const state = reactive<State>({
  reloadUsersTable: false,
  disabled: {
    create: false,
    edit: true,
  },
  errors: [],
  flashes: [],
})

const setup = () => {
  // 画面復帰時にユーザーテーブルがリロード必要な場合
  if (state.reloadUsersTable) {
    usersTable.reload()
    state.reloadUsersTable = false
  }

  onUnmounted(() => {
    // 消去していないフラッシュバナーが残るため、画面遷移時にフラッシュバナーを削除する
    state.flashes = []
  })

  const keys = usersTable.setup('admin-users-list')

  return keys
}

const addFlash = (text: string, icon?: string, color?: string) => {
  state.flashes.push({ text, icon, color })
}

const closeFlash = (text: string) => {
  const index = state.flashes.findIndex((flash) => text == flash.text)
  if (index >= 0) {
    state.flashes.splice(index, 1)
  }
}

const reloadTable = () => {
  usersTable.reload()
}

interface Store {
  state: State
  setup: () => KeyPair
  addFlash: (text: string, icon?: string, color?: string) => void
  closeFlash: (text: string) => void
  reloadTable: () => void
}

type S = Readonly<Store>
export type ListStore = S
const key: InjectionKey<S> = Symbol()
const store: BaseStore<S> = {
  key,
  state,
  setup,
  addFlash,
  closeFlash,
  reloadTable,
}

export default store
