<template>
  <router-view />
</template>

<script lang="ts">
import { defineComponent, provide } from 'vue'
import listStore from '@/store/admin/users/list'

export default defineComponent({
  name: 'AdminUsersIndex',
  setup() {
    provide(listStore.key, listStore)
  },
})
</script>
