
import { defineComponent } from 'vue'

import Table from '@/components/parts/Table.vue'
import Overlay from '@/components/parts/Overlay.vue'
import store from '@/store/admin/users/edit'

export default defineComponent({
  name: 'AdminUsersEdit',
  components: {
    Table,
    Overlay,
  },
  setup() {
    // provide(store.key, store)
    const groupsTableKey = store.setup()

    return {
      store,
      groupsTableKey,
    }
  },
})
