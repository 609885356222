import { isGraphQLError } from '@/graphql'
import {
  createUser as adminCreateUser,
  CreateUserErrorType,
} from '@/graphql/admin/users'
import { BaseStore } from '@/store'
import { KeyPair } from '@/store/module/table'
import ClipboardJS from 'clipboard'
import * as Password from 'generate-password'
import {
  InjectionKey,
  onMounted,
  onBeforeUnmount,
  reactive,
  watch,
  inject,
} from 'vue'

import { GroupsTable } from './groups-table'
import listStore from '@/store/admin/users/list'

const groupTable = new GroupsTable()

interface CreateUserInput {
  username: string
  password: string
  temporary: boolean
}

interface Loading {
  submit: boolean
}

interface Show {
  input: boolean
  complete: boolean
}

interface Disabled {
  submit: boolean
}

interface State {
  input: CreateUserInput
  show: Show
  loading: Loading
  disabled: Disabled
  errors: string[]
  result: string
  // ユーザー作成後、ユーザーテーブルを強制的にリロードさせるフラグ
  forceRealodUsersTable: boolean
}

const state = reactive<State>({
  input: {
    username: '',
    password: '',
    temporary: true,
  },
  show: {
    input: true,
    complete: false,
  },
  loading: {
    submit: false,
  },
  disabled: {
    submit: true,
  },
  errors: [],
  result: '',
  forceRealodUsersTable: false,
})

const watchInputSubmit = () => {
  // なにか入力されているかどうか
  const validInput =
    state.input.username.length > 0 && state.input.password.length > 0

  // グループにチェックが一つでも入っているかどうか
  const validGroupChecked = groupTable.isChecked

  state.disabled.submit = !(validInput && validGroupChecked)

  state.result = `ユーザー名:\n${state.input.username}\n\nパスワード:\n${state.input.password}\n`
}

const setup = () => {
  const keys = groupTable.setup('admin-groups-list')
  groupTable.onChecked(watchInputSubmit)

  const list = inject(listStore.key)

  onMounted(() => {
    resetState()
    showInput()

    watchInputSubmit()

    new ClipboardJS('.complete-result-copy', {
      text: () => state.result,
    })
  })

  onBeforeUnmount(() => {
    // ユーザー作成を行った場合で、ユーザーリストがある場合、ユーザーリストをリロードさせる
    if (list && state.forceRealodUsersTable) {
      list.state.reloadUsersTable = true
    }
  })

  // 入力監視
  watch(state.input, watchInputSubmit)

  return keys
}

const createUser = async () => {
  state.errors = []
  state.loading.submit = true

  try {
    await adminCreateUser(
      state.input.username,
      groupTable.checkedGroups,
      state.input.password,
      !state.input.temporary,
    )
    showComplete()
  } catch (err) {
    if (isGraphQLError(err)) {
      err.errors?.forEach((error) => {
        const e = Object.entries(CreateUserErrorType).find(
          ([type]) => type === error.errorType,
        )
        state.errors.push(e ? e[1] : error.message)
      })
    } else {
      console.log(err)
    }
  } finally {
    state.loading.submit = false
  }
}

const showComplete = () => {
  state.show.input = false
  state.show.complete = true
  state.forceRealodUsersTable = true
}

const showInput = () => {
  state.show.input = true
  state.show.complete = false

  state.input.username = ''
  state.input.password = ''
  state.disabled.submit = true

  setTimeout(() => {
    generatePassword()
  }, 800)
}

const resetState = () => {
  state.input.username = ''
  state.input.password = ''
  state.input.temporary = true
  state.errors = []
  state.show.input = true
  state.show.complete = false
  state.disabled.submit = true

  // グループリストのチェック状態を初期化
  groupTable.resetChecked()
}

const generatePassword = () => {
  state.input.password = Password.generate({
    uppercase: true,
    lowercase: true,
    numbers: true,
    strict: true,
    length: 8,
  })
}

interface Store {
  state: State
  setup: () => KeyPair
  createUser: () => Promise<void>
  generatePassword: () => void
  showInput: () => void
}

type S = Readonly<Store>
const key: InjectionKey<S> = Symbol()
const store: BaseStore<S> = {
  key,
  state,

  setup,
  createUser,
  generatePassword,
  showInput,
}

export default store
