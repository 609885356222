<template>
  <NavigationDrawer title="管理">
    <v-list-item to="/admin/users">
      <v-icon icon="mdi-account" class="mr-1" />ユーザー
    </v-list-item>
  </NavigationDrawer>

  <router-view />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import NavigationDrawer from '@/components/parts/NavigationDrawer.vue'

export default defineComponent({
  name: 'Admin',
  components: {
    NavigationDrawer,
  },
})
</script>
