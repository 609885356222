<template>
  <v-container :class="$style.container" fluid>
    <v-container class="flex-row" fluid>
      <v-row>
        <v-col class="text-subtitle-1 pr-0 font-weight-bold">
          ユーザーの作成
        </v-col>
      </v-row>
    </v-container>

    <form
      v-if="store.state.show.input"
      :class="$style.form"
      @submit.prevent="store.createUser"
    >
      <v-card elevation="2" tile :loading="store.state.loading.submit">
        <v-card-text>
          <input
            v-model.trim="store.state.input.username"
            :class="$style.input"
            name="username"
            type="text"
            placeholder="ユーザー名"
            required
          />
        </v-card-text>

        <v-card-text>
          <v-row no-gutters>
            <v-col cols="6" sm="8">
              <input
                v-model.trim="store.state.input.password"
                :class="$style.input"
                name="password"
                type="text"
                placeholder="パスワード"
                required
              />
            </v-col>
            <v-col cols="6" sm="4">
              <div class="ml-4">
                <v-btn
                  @click="store.generatePassword"
                  color="info"
                  block
                  variant="outlined"
                >
                  <span class="hidden-xs">パスワード</span><span>ランダム</span>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text>
          <label :class="$style.checkboxLabel">
            <input
              v-model="store.state.input.temporary"
              :class="$style.checkbox"
              name="temporary"
              type="checkbox"
            />
            仮パスワード（ログイン時に新しいパスワードを設定）
          </label>
        </v-card-text>

        <v-card-text v-if="store.state.errors.length">
          <ul
            :class="$style.errors"
            v-for="(error, key) in store.state.errors"
            :key="key"
          >
            <li>{{ error }}</li>
          </ul>
        </v-card-text>

        <v-card-item id="admin-groups-list" :class="$style.groupsTable">
          <Table :table-key="groupsTableKey" :errors="store.state.errors" />
        </v-card-item>

        <v-card-actions class="text-right">
          <v-btn to="/admin/users">キャンセル</v-btn>
          <v-btn type="submit" :disabled="store.state.disabled.submit">
            作成する
          </v-btn>
        </v-card-actions>
      </v-card>
    </form>

    <v-card
      v-if="store.state.show.complete"
      :class="$style.complete"
      elevation="2"
      tile
    >
      <v-card-header>
        <v-card-header-text>ユーザーを作成しました</v-card-header-text>
      </v-card-header>
      <v-card-text class="pb-0">
        <textarea
          v-model="store.state.result"
          :class="$style.result"
          spellcheck="off"
          autocomplete="off"
        ></textarea>
      </v-card-text>

      <v-card-actions>
        <v-btn class="complete-result-copy">クリップボードにコピー</v-btn>
        <v-btn class="mr-auto" @click="store.showInput">続けて作成する</v-btn>
        <v-btn to="/admin/users">戻る</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import Table from '@/components/parts/Table.vue'
import store from '@/store/admin/users/create'

export default defineComponent({
  name: 'AdminUsersCreate',
  components: {
    Table,
  },
  setup() {
    // provide(store.key, store)
    const groupsTableKey = store.setup()

    return {
      store,
      groupsTableKey,
    }
  },
})
</script>

<style module lang="scss">
@import '~vuetify/lib/styles/styles.sass';
.container {
  background-color: rgb(var(--v-theme-secondary));
  // header - footer
  height: calc(100vh - 48px - 34px);
  padding: 0;
}
.form {
  width: 100%;
  min-width: 300px;
  max-width: 700px;
  margin: auto;
}
.complete {
  width: 100%;
  min-width: 300px;
  max-width: 600px;
  margin: auto;
}
.input {
  border-bottom: 1px solid gray;
  padding: 10px;
  width: 100%;
}
.checkbox {
  padding: 10px;
}
.checkboxLabel {
  user-select: none;
}
.errors {
  padding: 0;
  margin: 0;
  color: rgb(var(--v-theme-error));
  font-weight: bold;
  list-style: none;
}
.result {
  width: 100%;
  height: 11em;
  padding: 16px;
  resize: none;
}
.groupsTable {
  height: calc(50vh - 48px - 34px);
}
</style>
