<template>
  <v-container fluid>
    <v-row>
      <v-col><h1>管理画面</h1></v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AdminIndex',
})
</script>
