
import { defineComponent } from 'vue'
import NavigationDrawer from '@/components/parts/NavigationDrawer.vue'

export default defineComponent({
  name: 'Admin',
  components: {
    NavigationDrawer,
  },
})
