<template>
  <v-container class="d-flex flex-column" :class="$style.container" fluid>
    <v-container class="flex-row" fluid>
      <v-row>
        <v-col cols="3" class="text-subtitle-1 pr-0 font-weight-bold">
          ユーザー
        </v-col>
        <v-col cols="9" class="text-right">
          <v-btn
            to="/admin/users/create"
            class="mx-1"
            color="primary"
            elevation="0"
            :disabled="store.state.disabled.create"
          >
            ユーザーの作成
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <div v-for="flash in store.state.flashes" :key="flash.text">
      <FlashBanner
        @close="store.closeFlash"
        :text="flash.text"
        :icon="flash.icon"
        :color="flash.color"
      />
    </div>

    <v-container
      id="admin-users-list"
      :class="$style.usersTable"
      fluid
    >
      <Table :table-key="usersTableKey" />
    </v-container>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue'

import Table from '@/components/parts/Table.vue'
import FlashBanner from '@/components/parts/FlashBanner.vue'
import listStore from '@/store/admin/users/list'

export default defineComponent({
  name: 'AdminUsersList',
  components: {
    Table,
    FlashBanner,
  },
  setup() {
    const store = inject(listStore.key)
    if (!store) {
      throw new Error()
    }

    const usersTableKey = store.setup()

    return {
      store,
      usersTableKey,
    }
  },
})
</script>

<style module lang="scss">
.container {
  background-color: rgb(var(--v-theme-secondary));
  // header - footer
  height: calc(100vh - 48px - 32px);
  padding: 0;
}
.usersTable {
  overflow: scroll;
  // header - footer - actions
  height: calc(100vh - 48px - 32px - 60px);
  padding: 0;
}
</style>
