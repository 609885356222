
import { defineComponent } from 'vue'

import Table from '@/components/parts/Table.vue'
import store from '@/store/admin/users/create'

export default defineComponent({
  name: 'AdminUsersCreate',
  components: {
    Table,
  },
  setup() {
    // provide(store.key, store)
    const groupsTableKey = store.setup()

    return {
      store,
      groupsTableKey,
    }
  },
})
