<template>
  <v-banner :class="`bg-${color}`" tile>
    <v-banner-text class="text-white" :class="$style.text">
      <v-icon v-if="icon" :icon="icon" class="mr-2" /><span v-html="text" />
    </v-banner-text>
    <v-icon
      title="閉じる"
      class="ml-auto"
      size="30"
      :class="$style.close"
      @click="onClose"
    >
      mdi-close
    </v-icon>
  </v-banner>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FlashBanner',
  emits: ['close'],
  props: {
    text: {
      type: String,
    },
    color: {
      type: String,
      default: 'success',
    },
    icon: {
      type: String,
    },
  },
  setup(props, context) {
    const onClose = () => {
      context.emit('close', props.text)
    }

    return { onClose }
  },
})
</script>

<style module lang="scss">
.text {
  position: relative;
}
.close {
  cursor: pointer;
  &:hover {
    opacity: var(--v-medium-emphasis-opacity);
  }
}
</style>
