import { isGraphQLError } from '@/graphql'
import { listUsers, UserStatus } from '@/graphql/admin/users'
import appStore from '@/store/app'
import { CellClickHandler, Table } from '@/store/module/table'
import moment from 'moment'
import { inject, watch } from 'vue'
import { Router, useRouter } from 'vue-router'

export class UsersTable extends Table {
  // 作成、変更日時の日付形式
  private dateFormat = 'YYYY-MM-DD HH:mm:ss'
  // 現在ログインしているユーザー名
  private loginUsername?: string
  // ルーター
  private router?: Router

  constructor() {
    super()

    this.columns = [
      { text: 'ユーザー名' },
      { text: 'ステータス', width: '10%' },
      { text: '有効', width: '10%', align: 'center' },
      { text: '作成日時', width: '10%', align: 'center' },
      { text: '変更日時', width: '10%', align: 'center' },
    ]
  }

  setup(targetParentElementId?: string) {
    const app = inject(appStore.key, appStore)
    this.loginUsername = app.state.loginUser?.getUsername()
    watch(app.state, (state) => {
      this.loginUsername = state.loginUser?.getUsername()
    })

    this.router = useRouter()

    return super.setup(targetParentElementId)
  }

  // ユーザー名がクリックされたときのハンドラ
  private onClickUsernameCell: CellClickHandler = (cell) => {
    const id = cell.text
    if (id && this.router) {
      this.router.push(`/admin/users/edit/${id}`)
    }
  }

  private getStatus(status: string) {
    return Object.entries(UserStatus).find(([key]) => key == status)?.[1]
  }

  protected async request() {
    if (this.pageRequesting) {
      return
    }

    this.pageRequesting = true
    try {
      const result = await listUsers(this.nextToken)
      const resultUsers = result.data?.adminListUsers
      this.nextToken = resultUsers?.nextToken
        ? (resultUsers.nextToken as string)
        : undefined

      resultUsers?.users.forEach((user) => {
        const usernameStyle =
          'color: rgb(var(--v-theme-primary)); text-decoration: underline; cursor: pointer'
        const username =
          user.id === this.loginUsername
            ? {
                text: user.id,
                html: `<span style="${usernameStyle}">${user.id}</span> <span class="text-button bg-primary rounded pa-1">自分</span>`,
              }
            : {
                text: user.id,
                style: usernameStyle,
              }

        this.table?.data.push([
          {
            ...username,
            click: this.onClickUsernameCell,
          },
          {
            text: this.getStatus(user.status),
          },
          {
            text: user.enabled ? '有効' : '無効',
            align: 'center',
          },
          {
            text: moment(user.createdAt).format(this.dateFormat),
            align: 'center',
          },
          {
            text: moment(user.updatedAt).format(this.dateFormat),
            align: 'center',
          },
        ])
      })
    } catch (err) {
      if (isGraphQLError(err)) {
        if (this.table?.data) {
          this.table.errors = []
        }

        err.errors?.forEach((error) => {
          if (error.errorType == 'Unauthorized') {
            this.table?.errors.push('表示する権限がありません')
          } else {
            console.log(error)
          }
        })
      } else {
        console.log(err)
      }
    } finally {
      this.pageRequesting = false
    }
  }
}
